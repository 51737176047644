import dashboardServices from '../../../services/notisphere/dashboard'
import { RECALL_CLASSIFICATIONS, THRESHOLD_COLOR_STATUS } from '../../../misc/commons'
import orgService from '../../../services/notisphere/organization'

export default {
    data() {
        return {
            status: 'OPEN',
            supplierOrgId: null,
            listSupplier: [],
            perPage: 50,
            perPageEmit: 50,
            currentPage: 1,
            per_page: [10, 20, 30, 40, 50],
            totalItems: null,
            items: [],
            recall_classifications: RECALL_CLASSIFICATIONS,
            threshold_color_status: THRESHOLD_COLOR_STATUS,
            minHeight: {
                meta: 0,
                title: 0,
                ack: 0,
                metric: 0,
            }
        }
    },
    created() {
        if (this._checkPermissions(['Notisphere_ViewDashboard'])){
            this.findSupplier()
            this.findRecallsCard()
        }
    },
    watch: {
        perPageEmit(val) {
            this.$emit('update:searchQuery', {
                pageNumber: this.currentPage,
                pageSize: val,
            })
            this.requestFetchData()
        },
        currentPage(val) {
            this.$emit('update:searchQuery', {
                pageNumber: val,
                pageSize: this.perPageEmit,
            })
            this.requestFetchData()
        },

    },
    methods: {
        findSupplier() {
            orgService.findCreatorMeta().then(resp => {
                this.listSupplier = resp.data.d
            })
        },
        findRecallsCard() {
            dashboardServices.findRecallsCard({
                pageNumber: this.currentPage,
                pageSize: this.perPage,
                sortField: null,
                sortDirection: null,
                status: this.status,
                supplierOrgId: this.supplierOrgId
            }).then(resp => {
                if (!resp.error){
                    this.items = resp.data.d.c
                    this.items.forEach(el => {
                        el.indicator = Math.round(el.total > 0 ? el.completed / el.total * 100 : 0)
                    })
                    this.totalItems = resp.data.d.t
                    this.$nextTick(()=>{
                        this.calcHeight()
                    })
                }
            })
        },
        requestFetchData() {
            //error on server mode ( when not in page 1 and make filter, it will emit twice the request) => set timeout
            if (this.timer2) {
                clearTimeout(this.timer2)
                this.timer2 = null
            }
            this.timer2 = setTimeout(() => {
                this.findRecallsCard()
            }, 5)
        },
        statusChange(status) {
            this.status = status
            this.findRecallsCard()
        },
        selectPerPage(perPage) {
            this.perPage = perPage
            this.perPageEmit = perPage
        },
        supplierChange() {
            this.findRecallsCard()
        },
        isActiveStatus(status) {
            return this.status == status ? 'btn btn-primary' : 'btn btn-secondary'
        },
        viewRecall(item) {
            if (item.allow_access == true && this._checkPermissions(['Notisphere_ManageRecalls'])){
                this.$router.push({ name: 'noti_RecallView', params: { id: item.communicationId } })
            }
        },
        calcHeight() {
            let maxHeight = (type) => {
                var maxheight = 0
                for (const prop in this.$refs) {
                    for (let i = 0; i < this.items.length; i++) {
                        if (prop == type+i) {
                            var height = this.$refs[prop][0].clientHeight
                            maxheight = maxheight <= height ? height : maxheight
                        }
                    }
                }
                return maxheight
            }

            this.minHeight = {
                meta: maxHeight('meta'),
                title: maxHeight('title'),
                ack: Math.max(maxHeight('ack'), maxHeight('notack')),
                metric: Math.max(maxHeight('metric1'), maxHeight('metric2'), maxHeight('metric3')),
            }
        }
    }
}